<template>
  <div class="page-content" v-if="post">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Post Detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <b-col cols="12">
          <b-form-group label="Title" label-for="h-title">
            <b-form-input
              id="h-title"
              placeholder="Title"
              v-model="post.title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Slug" label-for="h-slug">
            <b-form-input id="h-slug" placeholder="Slug" v-model="post.slug" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Language" label-for="h-language">
            <language-select
              :language="post.language"
              @selectedLanguage="selectedLanguage"
            />
          </b-form-group>
        </b-col>
      </b-card>
    </section>
    <section class="my-3">
      <h2 class="h2">Pages</h2>
      <draggable
        v-model="post.pages"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group type="transition" name="list">
          <app-collapse-item
            v-for="(page, indexPage) in post.pages"
            :key="indexPage || page._id"
            class="drag-item my-1"
            :is-visible="post.pages.length - 1 == indexPage"
            :title="page.title || ''"
          >
            <template slot="header">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-grow-1
                  w-100
                "
              >
                <span class="lead collapse-title">{{ page.title }}</span>
                <div style="position: absolute; right: 30px">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click.stop="removePage(indexPage)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </div>
            </template>
            <b-form-group label="Title" label-for="h-title">
              <b-form-input
                id="h-title"
                placeholder="Title"
                v-model="page.title"
              />
            </b-form-group>
            <b-form-group label="Content" label-for="h-content" />
            <ui-component-list :component-list="page.content" />
          </app-collapse-item>
        </transition-group>
      </draggable>
      <div class="text-center mt-1">
        <b-button pill variant="primary" @click="addPage">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Add Page</span>
        </b-button>
      </div>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deletePost"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      post: null,
      isUpdating: false,
      isDeleting: false,
    };
  },
  computed: {
    postId() {
      return this.$route.params.post_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Post List",
          to: { name: "post-list" },
        },
      ];
      items.push({ text: "Post Detail", active: true });
      return items;
    },
  },
  async created() {
    await this.getPost();
  },
  methods: {
    async getPost() {
      this.post = await service.get({ id: this.postId });
    },
    addPage() {
      this.post.pages.push({ title: null, content: [] });
    },
    removePage(indexPage) {
      this.post.pages.splice(indexPage, 1);
    },
    async update() {
      this.isUpdating = true;
      let response;
      response = await service.update({ data: JSON.stringify(this.post) });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully updated lesson!",
        });
      }
      this.getPost();
      this.isUpdating = false;
    },
    async deletePost() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.postId });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully deleted lesson!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "post-list" });
    },
    selectedLanguage(language) {
      this.post.language = language;
    },
  },
};
</script>
