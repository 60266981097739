import api from '@/libs/axios'

export default {
  async getPostList(params) {
    let response = await api.get('/admin/posts', {
      params,
    })
    return response
  },
  async updatePost(payload) {
    let response = await api.put('/admin/posts', payload)
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/posts', payload)
    return response.data.data.new_document || null
  },
  async get(params) {
    let response = await api.get('/admin/posts/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async update(payload) {
    let response = await api.put('/admin/posts', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/posts', {
      data: payload,
    })
    return response
  },
}
